<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
        <v-layout
                justify-center
                class="overflow-x-auto"
                wrap
        >
            <template v-if="loaded">
                <data-table ref="datatable"
                            :api-end-point="apiurl"
                            :search="search"
                            :sort-order="sortOrder"
                            :is-custom-filter="isCustomFilter"
                            :table-name="'Subscription Notifications'"
                            :isExport="true"
                >
                </data-table>
            </template>
        </v-layout>
    </v-container>
</template>

<script>
    import DataTable from "../../components/common/dataTable/DataTable";
    export default {
        name: "SubscriptionDetails",
        components: {
          DataTable
        },
        data() {
            return {
                apiurl: 'subscription/'+this.$route.params.id ,
                search:{
                    placeholder:  'Search by notification type, payload…',
                    searchAble: false
                },
                sortOrder: [
                    {
                        field: 'id',
                        sortField: 'id',
                        direction: 'desc'
                    }
                ],
                filters:[],
                selected_filters:[],
                loaded: false,
                isCustomFilter: false,
            }
        },
        mounted() {
            this.loaded = true;
        }
    }
</script>

<style scoped>

</style>